<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
                    <a-form-model-item label="" prop="(id">
                <a-input v-model="rowData.id" placeholder=""></a-input>
            </a-form-model-item>
            <a-form-model-item label="规格名ID" prop="(specId">
                <a-input v-model="rowData.specId" placeholder="规格名ID"></a-input>
            </a-form-model-item>
            <a-form-model-item label="规格值名称" prop="(name">
                <a-input v-model="rowData.name" placeholder="规格值名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="排序" prop="(sort">
                <a-input v-model="rowData.sort" placeholder="排序"></a-input>
            </a-form-model-item>
            <a-form-model-item label="是否启用" prop="(flagEnable">
                <a-input v-model="rowData.flagEnable" placeholder="是否启用"></a-input>
            </a-form-model-item>
            <a-form-model-item label="创建人" prop="(createUser">
                <a-input v-model="rowData.createUser" placeholder="创建人"></a-input>
            </a-form-model-item>
            <a-form-model-item label="创建人id" prop="(createUserId">
                <a-input v-model="rowData.createUserId" placeholder="创建人id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="创建时间" prop="(createTime">
                <a-input v-model="rowData.createTime" placeholder="创建时间"></a-input>
            </a-form-model-item>
            <a-form-model-item label="修改人" prop="(modifyUser">
                <a-input v-model="rowData.modifyUser" placeholder="修改人"></a-input>
            </a-form-model-item>
            <a-form-model-item label="修改人id" prop="(modifyUserId">
                <a-input v-model="rowData.modifyUserId" placeholder="修改人id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="修改时间" prop="(modifyTime">
                <a-input v-model="rowData.modifyTime" placeholder="修改时间"></a-input>
            </a-form-model-item>
            <a-form-model-item label="是否删除" prop="(flagDel">
                <a-input v-model="rowData.flagDel" placeholder="是否删除"></a-input>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="(remark">
                <a-input v-model="rowData.remark" placeholder="备注"></a-input>
            </a-form-model-item>
            <a-form-model-item label="版本号" prop="(version">
                <a-input v-model="rowData.version" placeholder="版本号"></a-input>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editProductBaseSpecValue, selectByIdProductBaseSpecValue, addProductBaseSpecValue } from '../api/ProductBaseSpecValueApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                id: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                specId: [
                    { required: true, message: '请输入规格名ID', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '请输入规格值名称', trigger: 'blur' }
                ],
                sort: [
                    { required: true, pattern:new RegExp(/^[0-9]+$/), message: '请输入排序(正整数)', trigger: 'blur' }
                ],
                flagEnable: [
                    { required: true, message: '请输入是否启用', trigger: 'blur' }
                ],
                createUser: [
                    { required: true, message: '请输入创建人', trigger: 'blur' }
                ],
                createUserId: [
                    { required: true, message: '请输入创建人id', trigger: 'blur' }
                ],
                createTime: [
                    { required: true, message: '请输入创建时间', trigger: 'blur' }
                ],
                modifyUser: [
                    { required: true, message: '请输入修改人', trigger: 'blur' }
                ],
                modifyUserId: [
                    { required: true, message: '请输入修改人id', trigger: 'blur' }
                ],
                modifyTime: [
                    { required: true, message: '请输入修改时间', trigger: 'blur' }
                ],
                flagDel: [
                    { required: true, message: '请输入是否删除', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '请输入备注', trigger: 'blur' }
                ],
                version: [
                    { required: true, message: '请输入版本号', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = row
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addProductBaseSpecValue(this.rowData) : await editProductBaseSpecValue(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
